import {captureException} from '@sentry/vue';
import {RequestError} from 'vue-mc';

export default {
    methods: {
        /**
         * Execute an async callback, then show success / failure feedback
         * using toast.
         *
         * @param {Function} callback
         * @param {Object} option
         */
        async tryCatchWithToast(callback, option) {
            let toast = {
                title: option.success,
                variant: 'primary',
            };

            try {
                await callback();
            } catch (error) {
                captureException(error);
                const title = error instanceof RequestError
                    ? error.getResponse().getErrorMessage()
                    : error.toString();

                toast = {
                    title,
                    variant: 'danger',
                };
            }

            if (toast.title) {
                this.$bvToast.toast(' ', toast);
            }
        },
    },
};
