<template>
    <div>
        <!-- Undo & Save Buttons -->
        <portal
            v-if="activated"
            to="client-edit-actions"
        >
            <b-btn
                variant="light"
                class="btn-min-width mr-3"
                :disabled="false"
                @click="medias.reset()"
            >
                {{ $t('common.actions.undoChanges') }}
            </b-btn>

            <btn-resource
                variant="primary"
                class="btn-min-width"
                :resource="medias"
                :disabled="false"
                @click="saveMedias()"
            >
                {{ $t('common.actions.saveChanges') }}
            </btn-resource>
        </portal>

        <!-- Add Media Card -->
        <b-card body-class="d-flex align-items-center">
            <h4>
                {{ $t('clients.edit.media.nav') }}
            </h4>

            <div
                v-b-modal="'modal-add-media'"
                class="ml-auto cursor-pointer"
                tabindex="-1"
            >
                {{ $t('common.actions.addMedia') }}

                <btn-square
                    class="ml-3"
                    variant="dark"
                >
                    <fa icon="plus" />
                </btn-square>
            </div>

            <media-modal
                id="modal-add-media"
                :types="medias.types"
                :privacies="medias.privacies"
                :initial-selection="medias.models"
                :no-items-text="$t('posts.messages.noPrivateMediasFound')"
                @add-media="medias.replace($event)"
            />
        </b-card>

        <!-- Medias Cards Grid-->
        <wait-for-resource
            :resource="medias"
            class="mt-3"
        >
            <grid-list
                cols="6"
                :items="medias.models"
            >
                <template #default="{item}">
                    <media-card
                        class="bg-white"
                        :post="item"
                        hide-privacy
                    >
                        <template #details="{media}">
                            <btn-square
                                variant="light"
                                class="text-danger ml-auto"
                                @click="medias.remove(media)"
                            >
                                <fa :icon="['fac', 'trash']" />
                            </btn-square>
                        </template>
                    </media-card>
                </template>
            </grid-list>
        </wait-for-resource>
    </div>
</template>

<script>
import {Posts} from '@/models/Post';
import Image from '@/library/Image';
import {Client} from '@/models/Client';
import GridList from '@/components/common/GridList';
import MediaCard from '@/components/media/MediaCard';
import PostType from '@/library/enumerations/PostType';
import MediaModal from '@/components/media/MediaModal';
import PostPrivacy from '@/library/enumerations/PostPrivacy';
import KeptAlive from '@/components/common/mixins/KeptAlive';
import TryCatchWithToast from '@/components/common/mixins/TryCatchWithToast';
import ConfirmLeaveUnsaved from '@/components/common/mixins/ConfirmLeaveUnsaved';

export default {
    name: 'ClientEditMedia',
    components: {MediaCard, GridList, MediaModal},
    mixins: [ConfirmLeaveUnsaved, KeptAlive, TryCatchWithToast],
    props: {
        client: {
            type: Client,
            required: true,
        },
    },
    data: function() {
        return {
            medias: new Posts([], {
                endpoint: 'related-posts',
                routePrefix: `users/${this.client.user.id}`,
            }),
        };
    },
    computed: {
        hasUnsavedChanges() {
            return !!this.medias.changed();
        },
    },
    async created() {
        // Fetch only media posts that are directly related to the client.
        this.medias.types = [PostType.VIDEO, PostType.AUDIO];

        this.medias.privacies = [PostPrivacy.RELATED_TO_POST];

        await this.medias.fetch();
    },
    methods: {
        saveMedias() {
            this.tryCatchWithToast(async() => {
                await this.medias.save();
            }, {
                success: this.$t('clients.messages.saveMediaSuccess'),
            });
        },
    },
};
</script>
