<template>
    <b-card class="h-100 border-0">
        <div class="d-flex justify-content-between">
            <h5> {{ post.title }} </h5>

            <slot />
        </div>
    </b-card>
</template>

<script>
import {Post} from '@/models/Post';

/**
 * Card representation of a program. Used in MediaModal.
 *
 * Exposes slots:
 * - Default -> Meant for the checkbox on top right corner.
 */
export default {
    name: 'ProgramCard',
    props: {
        post: {
            type: Post,
            required: true,
        },
    },
};
</script>
