<template>
    <b-modal
        ref="modal"
        v-bind="$attrs"
        size="xl"
        content-class="media-modal"
        body-class="py-4"
        centered
        hide-header
        hide-footer
        @shown="onModalShown()"
    >
        <!-- Modal Header -->
        <div class="d-flex align-items-end justify-content-between mb-5">
            <h3 class="align-self-center">
                {{ $t(`common.actions.add${isProgramsModal ? 'Programs' : 'Media'}`) }}
            </h3>

            <!-- Instruction on how to create media / program. -->
            <i18next
                :path="instruction.messagePath"
                tag="label"
                class="small ml-3"
            >
                <b-link
                    :to="instruction.linkLocation"
                    target="_blank"
                    class="text-primary"
                >
                    {{ instruction.linkText }}
                </b-link>
            </i18next>

            <input-search
                v-model="collection.searchQuery"
                class="ml-auto"
            />

            <b-btn
                variant="dark"
                class="btn-min-width ml-3"
                @click="onAddMedia()"
            >
                {{ $t(`common.actions.add${isProgramsModal ? 'Programs' : 'Media'}`) }}
            </b-btn>
        </div>

        <!-- Show helpful message when no programs / media are found. -->
        <div
            v-if="!collection.loading && collection.isEmpty()"
            class="text-center text-muted"
        >
            {{ noItemsText }}
        </div>

        <!-- Collection Grid -->
        <grid-list
            ref="collectionGrid"
            v-infinite-scroll="fetchCollection"
            class="vh-75 overflow-y-auto align-content-start"
            :items="collection.models"
            cols="6"
            md="4"
            lg="3"
        >
            <template #default="{item}">
                <keep-alive>
                    <component
                        :is="cardComponent"
                        :post="item"
                        :show-share="false"
                        :class="{selected: indexInSelection(item) > -1}"
                        class="bg-light cursor-pointer"
                        @click.native="changeSelection(item, indexInSelection(item) === -1)"
                    >
                        <!-- Put checkbox in the header's top right -->
                        <ow-checkbox
                            class="position-absolute"
                            style="top: 1rem; right: 1rem;"
                            :value="indexInSelection(item) > -1"
                        />
                    </component>
                </keep-alive>
            </template>
        </grid-list>

        <!-- Loader -->
        <wait-for-resource :resource="collection" />
    </b-modal>
</template>

<script>
import {Posts} from '@/models/Post';
import GridList from '@/components/common/GridList';
import MediaCard from '@/components/media/MediaCard';
import ProgramCard from '@/components/programs/Card';
import PostType from '@/library/enumerations/PostType';
import OwCheckbox from '@/components/common/OwCheckbox';
import InputSearch from '@/components/common/form/InputSearch';
import QueryableCollection from '@/components/common/mixins/QueryableCollection';

export default {
    name: 'MediaModal',
    components: {OwCheckbox, InputSearch, GridList},
    mixins: [QueryableCollection],
    props: {
        /**
         * The post types that should be fetched from API.
         */
        types: {
            type: Array,
            default: () => [PostType.VIDEO, PostType.AUDIO],
        },
        /**
         * The post privacies that should be fetched from API.
         */
        privacies: {
            type: Array,
            default: () => [],
        },
        initialSelection: {
            type: Array,
            default: () => [],
        },
        noItemsText: {
            type: String,
            default() {
                return this.$t('posts.messages.noMediasFound');
            },
        },
    },
    data: function() {
        return {
            collection: new Posts([], {
                routePrefix: this.$me.getFetchURL(),
            }),
            /**
             * An array of selected posts.
             */
            selection: [],
        };
    },
    computed: {
        /**
         * A boolean indicator that tells if this modal is showing programs
         * or non-programs.
         */
        isProgramsModal() {
            return this.types[0] === PostType.PROGRAM;
        },
        /**
         * Determine the post card component that should be rendered.
         */
        cardComponent() {
            return this.isProgramsModal ? ProgramCard : MediaCard;
        },
        /**
         * An object containing instruction on how to create program / media.
         */
        instruction() {
            return {
                messagePath: `posts.messages.linkTo${this.isProgramsModal ? 'CreateProgram' : 'UploadMedia'}`,
                linkLocation: this.isProgramsModal ? this.$me.getLocation('programs.index') : {name: 'posts.create'},
                linkText: this.$t(this.isProgramsModal ? 'programs.programs' : 'posts.create.title'),
            };
        },
        scrollingElement() {
            return this.$refs.collectionGrid.$el;
        },
    },
    methods: {
        /**
         * Fetch the posts collection when the modal is opened for the first time.
         */
        async onModalShown(){
            this.selection = [...this.initialSelection];

            if (this.collection.isEmpty()) {
                this.collection.types = this.types;

                this.collection.privacies = this.privacies;

                await this.fetchCollectionUntilScrollable();
            }
        },
        /**
         * @return {number} Index of the given post in the selection array.
         */
        indexInSelection(post) {
            return this.selection.findIndex(item => item.id === post.id);
        },
        /**
         * Change the selection by adding / removing the given post.
         */
        changeSelection(post, isSelected) {
            const index = this.indexInSelection(post);
            const wasSelected = index > -1;

            if (isSelected) {
                if (!wasSelected) this.selection.push(post);
            } else {
                if (wasSelected) this.selection.splice(index, 1);
            }
        },
        /**
         * Emits the selected posts and hides the modal.
         */
        onAddMedia() {
            this.$emit('add-media', this.selection);

            this.$refs.modal.hide();
        },
    },
};
</script>

<style lang="scss">
.media-modal {
    .selected {
        opacity: .8;
    }
}
</style>
